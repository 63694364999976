import React from "react";
import { Button } from "antd";

const DataTableKuota = ({ data }) => {
  const getQuotaClass = (quota) => {
    if (quota === "Data Tidak Tersedia") return "bg-gray-200";

    const [used, total] = quota.split(" / ").map((num) => parseInt(num, 10));

    if (isNaN(used) || isNaN(total)) return "bg-gray-200";
    if (used >= total) return "bg-red";
    if (used >= total * 0.95) return "bg-yellow";
    return "bg-green";
  };

  return (
    <div className="w-full">
      <table className="min-w-full bg-white border">
        <thead>
          <tr className="bg-blue-600 text-white">
            <th className="py-3 px-4 text-left">Produk</th>
            <th className="py-3 px-4 text-left">Layanan</th>
            <th className="py-3 px-4 text-left">Provider</th>
            <th className="py-3 px-4 text-left">Realisasi Kuota</th>
            <th className="py-3 px-4 text-left">Detail</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(data) &&
            data.map((item, index) => (
              <tr key={index} className="border-b">
                <td className="py-2 px-4">{item.product}</td>
                <td className="py-2 px-4">{item.service}</td>
                <td className="py-2 px-4">{item.provider}</td>
                <td className="py-2 px-4 flex items-center">
                  <div className="flex flex-col">
                    <span
                      className={`inline-block rounded text-center px-2 py-1 ${getQuotaClass(
                        item.quota
                      )}`}
                    >
                      {item.quota}
                    </span>
                    <div className="font-semibold text-xs text-center text-gray-500">
                      {item.lastUpdated}
                    </div>
                  </div>
                  <button>
                    <img
                      src="/images/reload.png"
                      alt="Refresh Icon"
                      className="ml-5 w-6 h-6"
                    />
                  </button>
                </td>
                <td className="py-2 px-4">
                  <Button type="link">Lihat Detail</Button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center">
          <span className="mr-2">Rows per page :</span>
          <select className="border rounded p-1">
            <option>10</option>
            <option>50</option>
          </select>
        </div>
        <div className="flex items-center">
          <span>1-10 of 100</span>
          <button className="ml-2 p-2 border rounded">&lt;</button>
          <button className="ml-2 p-2 border rounded">&gt;</button>
        </div>
      </div>
    </div>
  );
};

export default DataTableKuota;
