// import React from "react";
// import { Layout, Menu, Button } from "antd";
// import {
//   BarChartOutlined,
//   EditOutlined,
//   FormOutlined,
//   CheckCircleOutlined,
//   FileTextOutlined,
// } from "@ant-design/icons";
// import Image from "../../atoms/Image";

// const { Sider } = Layout;

// const Sidebar = () => {
//   const siderWidth = "20vw";
//   return (
//     <Sider
//       width={siderWidth}
//       className="drop-shadow-md p-4 bg-white"
//       theme="light"
//     >
//       <Button className="w-full mb-4 bg-primary-blue text-white hover:bg-secondary-blue">
//         Daftar Produk Baru
//       </Button>
//       <Menu theme="light" style={{ border: "none" }}>
//         <Menu.Item key="1" icon={<BarChartOutlined />}>
//           Monitoring Usage
//         </Menu.Item>
//         <Menu.Item key="2" icon={<EditOutlined />}>
//           Penambahan Kuota
//         </Menu.Item>
//         <Menu.Item key="3" icon={<FormOutlined />}>
//           Monitoring Pengajuan
//         </Menu.Item>
//         <Menu.Item key="4" icon={<CheckCircleOutlined />}>
//           Approval
//         </Menu.Item>
//         <Menu.Item key="5" icon={<FileTextOutlined />}>
//           Daftar Layanan
//         </Menu.Item>
//       </Menu>
//     </Sider>
//   );
// };

// export default Sidebar;

// components/organisms/Sidebar.js
import React from "react";
import { Layout, Menu, Button } from "antd";
import { Link } from "react-router-dom";
import {
  BarChartOutlined,
  EditOutlined,
  FormOutlined,
  CheckCircleOutlined,
  FileTextOutlined,
} from "@ant-design/icons";

const { Sider } = Layout;

const Sidebar = () => {
  const siderWidth = "20vw";
  return (
    <Sider width={siderWidth} className="p-4" theme="light">
      <Menu
        className="mt-4"
        theme="light"
        mode="inline"
        style={{ border: "none" }}
      >
        <Link to="/tambah-produk">
          <Button className="w-full h-11 bg-primary-blue text-white flex items-center group">
            <img
              src="/images/Pen.png"
              alt="Filter Icon"
              className="w-4 h-4 transition duration-200 ease-in-out group-hover:filter-blue"
            />
            Daftar Produk Baru
          </Button>
        </Link>
      </Menu>

      <Menu
        className="mt-4"
        theme="light"
        mode="inline"
        style={{ border: "none" }}
      >
        <Menu.Item key="1" icon={<BarChartOutlined />}>
          <Link to="/pemantauan-pengguna">Monitoring Usage</Link>
        </Menu.Item>
        <Menu.Item key="2" icon={<EditOutlined />}>
          <Link to="/penambahan-kuota">Penambahan Kuota</Link>
        </Menu.Item>
        <Menu.Item key="3" icon={<FormOutlined />}>
          <Link to="/pemantauan-pengajuan">Monitoring Pengajuan</Link>
        </Menu.Item>
        <Menu.Item key="4" icon={<CheckCircleOutlined />}>
          <Link to="/approval">Approval</Link>
        </Menu.Item>
        <Menu.Item key="5" icon={<FileTextOutlined />}>
          <Link to="/daftar-layanan">ServiceList</Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
