// import React from "react";
// import { Layout, Breadcrumb } from "antd";
// import Sidebar from "./components/organisms/Sidebar";
// import Image from "./components/atoms/Image";

// const { Header, Content } = Layout;

// const App = () => {
//   return (
//     <Layout className="w-full h-screen">
//       <Header className="flex z-10 items-center bg-white drop-shadow-md h-24">
//         <Image
//           src="../images/LOGO E-CDD.png"
//           alt="Logo"
//           width="80%"
//           height="auto"
//         />
//       </Header>
//       <Layout>
//         <Sidebar />
//         <Layout className="flex px-12 py-8 flex-col space-y-2">
//           <Breadcrumb>
//             <Breadcrumb.Item>Home</Breadcrumb.Item>
//             <Breadcrumb.Item>List</Breadcrumb.Item>
//             <Breadcrumb.Item>App</Breadcrumb.Item>
//           </Breadcrumb>
//           <Content className="p-12 bg-white rounded-lg drop-shadow-md">
//             Content
//           </Content>
//         </Layout>
//       </Layout>
//     </Layout>
//   );
// };

// export default App;

// App.js
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./components/templates/Layout";
import MonitoringUsage from "./components/pages/MonitoringUsage";
import QuotaAddition from "./components/pages/QuotaAddition";
import MonitoringSubmission from "./components/pages/MonitoringSubmission";
import Approval from "./components/pages/Approval";
import ServiceList from "./components/pages/ServiceList";
import { BreadcrumbProvider } from "./context/BreadCrumbContext";
import PenambahanKuota from "./components/pages/QuotaAddition/PenambahanKuota";
import DetailPengajuan from "./components/pages/MonitoringSubmission/DetailPengajuan";
import ServiceInfo from "./components/pages/ServiceList/ServiceInfo";
import DetailPengajuanApproval from "./components/pages/Approval/DetailPengajuanApproval";
import AddNewProduct from "./components/pages/AddNewProduct";

const App = () => {
  return (
    <BreadcrumbProvider>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/" element={<Layout />}>
            <Route path="tambah-produk" element={<AddNewProduct />} />
            <Route path="/" element={<MonitoringUsage />} />
            <Route path="pemantauan-pengguna" element={<MonitoringUsage />} />
            <Route path="penambahan-kuota" element={<QuotaAddition />} />
            <Route
              path="/penambahan-kuota/tambah-kuota"
              element={<PenambahanKuota />}
            />
            <Route
              path="pemantauan-pengajuan"
              element={<MonitoringSubmission />}
            />
            <Route
              path="/monitoring-pengajuan/detail-pangajuan"
              element={<DetailPengajuan />}
            />
            <Route path="approval" element={<Approval />} />
            <Route
              path="/approval/detail-pengajuan-approval"
              element={<DetailPengajuanApproval />}
            />
            <Route path="daftar-layanan" element={<ServiceList />} />
            <Route
              path="/daftar-layanan/service-info"
              element={<ServiceInfo />}
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </BreadcrumbProvider>
  );
};

export default App;
