import React from "react";

const Outlet = ({ children, titlePage, description }) => {
  return (
    <React.Fragment>
      <div className="flex">
        <div className="flex flex-col ">
          <h1 className="font-bold text-3xl">{titlePage}</h1>
          <h1 className="font-semibold text-xs text-gray-500">{description}</h1>
        </div>
        <div>{children}</div>
      </div>
    </React.Fragment>
  );
};

export default Outlet;
