import React, { useState } from "react";
import { Layout as AntLayout, Breadcrumb, Modal, Button } from "antd";
import { Outlet, useLocation, Link } from "react-router-dom";
import Sidebar from "../../organisms/Sidebar";
import Image from "../../atoms/Image";
import { useBreadcrumb } from "../../../context/BreadCrumbContext";
import { LeftOutlined } from "@ant-design/icons";
import "antd/dist/reset.css";

const { Header, Content } = AntLayout;

const formatText = (text) => {
  return text
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

const Layout = () => {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const { isBreadcrumbVisible } = useBreadcrumb();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <AntLayout className="w-full h-screen">
      <Header className="flex z-10 items-center bg-white drop-shadow-md h-24">
        <div className="w-full h-fit flex justify-between">
          <Image
            src="../images/LOGO E-CDD.png"
            alt="Logo"
            width="90%"
            height="auto"
          />
          <Button type="link" onClick={showModal}>
            <div className="flex items-center ">
              <span>00321763 / Luthfi </span>
            </div>
          </Button>
        </div>
      </Header>
      <AntLayout>
        <Sidebar />
        <AntLayout className="w-full h-fit flex px-12 py-8 flex-col space-y-2">
          {isBreadcrumbVisible && (
            <Breadcrumb separator=">">
              <Breadcrumb.Item key="home"></Breadcrumb.Item>
              {pathnames.map((name, index) => {
                const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`;
                const formattedName = formatText(name);
                return (
                  <Breadcrumb.Item key={routeTo}>
                    <Link to={routeTo}>{formattedName}</Link>
                  </Breadcrumb.Item>
                );
              })}
            </Breadcrumb>
          )}
          <Content className="p-4 h-fit bg-white rounded-lg drop-shadow-md">
            <Outlet />
          </Content>
        </AntLayout>
      </AntLayout>
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        centered
        width={300}
        style={{ top: 80, right: 20, position: "absolute" }}
      >
        <div style={{ padding: "16px" }}>
          <div
            style={{
              marginTop: "20px",
              padding: "10px 16px",

              borderRadius: "8px",
            }}
          >
            <Button type="link" onClick={handleOk} style={{ padding: 0 }}>
              <img
                src="/images/Left.png"
                alt="Filter Icon"
                className="w-4 h-4 filter-blue-hover"
              />
              Kembali Ke BRIStars
            </Button>
          </div>
          <div
            style={{
              marginTop: "20px",
              padding: "10px 16px",

              borderRadius: "8px",
            }}
          >
            <Button type="link" onClick={handleOk} style={{ padding: 0 }}>
              <img
                src="/images/Log Out.png"
                alt="Filter Icon"
                className="w-4 h-4 filter-blue-hover"
              />
              Keluar
            </Button>
          </div>
        </div>
      </Modal>
    </AntLayout>
  );
};

export default Layout;
