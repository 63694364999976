import React, { useEffect } from "react";
import { Table, Button, Tag } from "antd";
import { useBreadcrumb } from "../../../context/BreadCrumbContext";
import Outlet from "../../organisms/Outlet";
import { Link } from "react-router-dom";

const data = [
  {
    key: "1",
    layanan: "Layanan 1",
    provider: "Privy",
  },
  {
    key: "2",
    layanan: "Layanan 2",
    provider: "Privy",
  },
  {
    key: "3",
    layanan: "Layanan 2",
    provider: "Privy",
  },
  {
    key: "4",
    layanan: "Layanan 3",
    provider: "Privy",
  },
  {
    key: "5",
    layanan: "Layanan 5",
    provider: "Privy",
  },
  {
    key: "6",
    layanan: "Layanan 6",
    provider: "Privy",
  },
];

const columns = [
  {
    title: "Layanan",
    dataIndex: "layanan",
    key: "layanan",
  },
  {
    title: "Provider",
    dataIndex: "provider",
    key: "provider",
  },
  {
    title: "Detail",
    key: "detail",
    render: () => (
      <Link to="/daftar-layanan/service-info" className="flex items-center">
        <Button type="primary">Lihat Deatil</Button>
      </Link>
    ),
  },
];

const ServiceList = () => {
  const { setIsBreadcrumbVisible } = useBreadcrumb();

  useEffect(() => {
    setIsBreadcrumbVisible(false);
    return () => setIsBreadcrumbVisible(true);
  }, [setIsBreadcrumbVisible]);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center my-4">
        <Outlet titlePage="Daftar Layanan E-CDD" description="" />
      </div>
      <Table
        className="[&_.ant-table-thead>tr>th]:!bg-primary-blue [&_.ant-table-thead>tr>th]:!text-white"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default ServiceList;
