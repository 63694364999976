import React from "react";
import { Image as AntImage } from "antd";

const Image = ({ src, alt, width, height }) => {
  return (
    <AntImage src={src} alt={alt} preview={false} style={{ width, height }} />
  );
};

export default Image;
