import React from "react";
import { useState, useEffect } from "react";
import { Steps, Button, Table, Input, Modal, Upload } from "antd";
import "antd/dist/reset.css";
import Outlet from "../../../organisms/Outlet";
import {
  UploadOutlined,
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Step } = Steps;

const ECDDForm = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [checkers, setCheckers] = useState([{ id: 1, value: "" }]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmittedModalVisible, setIsSubmittedModalVisible] = useState(false);
  const navigate = useNavigate();

  //Product Owner//
  const [productOwners, setProductOwners] = useState([
    { id: 1, name: "", phone: "" },
  ]);

  const addProductOwner = () => {
    const newId = productOwners.length + 1;
    setProductOwners([...productOwners, { id: newId, name: "", phone: "" }]);
  };

  const removeProductOwner = (id) => {
    if (productOwners.length > 1) {
      setProductOwners(productOwners.filter((owner) => owner.id !== id));
    }
  };
  //------------//

  //PIC DEVELOPER//
  const [developers, setDevelopers] = useState([
    { id: 1, name: "", phone: "" },
  ]);

  const addDeveloper = () => {
    const newId = developers.length + 1;
    setDevelopers([...developers, { id: newId, name: "", phone: "" }]);
  };

  const removeDeveloper = (id) => {
    if (developers.length > 1) {
      setDevelopers(developers.filter((dev) => dev.id !== id));
    }
  };
  //------------//

  const handleNext = () => {
    if (currentStep < 2) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleAddChecker = () => {
    const newId = checkers.length ? checkers[checkers.length - 1].id + 1 : 1;
    setCheckers([...checkers, { id: newId, value: "" }]);
  };

  const handleRemoveChecker = (id) => {
    setCheckers(checkers.filter((checker) => checker.id !== id));
  };

  const handleCheckerChange = (id, value) => {
    setCheckers(
      checkers.map((checker) =>
        checker.id === id ? { ...checker, value } : checker
      )
    );
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setIsSubmittedModalVisible(true);
  };

  const handleOkSubmit = () => {
    setIsSubmittedModalVisible(false);
    navigate("/pemantauan-pengajuan");
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Layanan",
      dataIndex: "layanan",
      key: "layanan",
    },
    {
      title: "Provider Penyedia",
      dataIndex: "provider",
      key: "provider",
    },
    {
      title: "Kuota",
      dataIndex: "kuota",
      key: "kuota",
      render: (text) => (
        <span
          className={`px-2 py-1 rounded-full ${
            text === "Tersedia"
              ? "bg-green-100 text-green-700"
              : "bg-red-100 text-red-700"
          }`}
        >
          {text}
        </span>
      ),
    },
    {
      title: "Kebutuhan",
      dataIndex: "kebutuhan",
      key: "kebutuhan",
      render: (text, record) => (
        <Input
          placeholder="Jumlah"
          disabled={record.kuota !== "Tersedia"}
          className="mt-1 block w-full h-12 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200"
        />
      ),
    },
  ];

  const data = [
    {
      key: "1",
      layanan: "Layanan 1",
      provider: "Privy",
      kuota: "Tersedia",
    },
    {
      key: "2",
      layanan: "Layanan 2",
      provider: "Privy",
      kuota: "Tersedia",
    },
    {
      key: "3",
      layanan: "Layanan 3",
      provider: "Privy",
      kuota: "Tersedia",
    },
    {
      key: "4",
      layanan: "Layanan 4",
      provider: "Privy",
      kuota: "Tidak Tersedia",
    },
    {
      key: "5",
      layanan: "Layanan 5",
      provider: "Privy",
      kuota: "Tersedia",
    },
  ];

  const steps = [
    {
      title: "Pengisian Form",
      content: (
        <>
          <div className="border-b-2 flex flex-row gap-5">
            <div className="w-1/2">
              <form>
                <div className="mb-4">
                  <label className="block text-gray-700">Nama Produk</label>
                  <input
                    type="text"
                    className="mt-1 block w-full h-12 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200"
                    placeholder="BRImo"
                  />
                </div>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Alasan Pengajuan
                  </label>
                  <input
                    type="text"
                    className="mt-1 block w-full h-12 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200"
                    placeholder="Masukkan alasan pengadaan"
                  />
                </div>
              </form>
            </div>
            <div className="w-1/2">
              <form>
                <div className="mb-4">
                  <label className="block text-gray-700">
                    Nama Divisi Pemilik Produk
                  </label>
                  <input
                    type="text"
                    className="mt-1 block w-full h-12 border border-gray-300 rounded-md shadow-sm focus:ring focus:ring-blue-200"
                    placeholder="FST"
                  />
                </div>
                <div className="mb-4 w-full">
                  <div></div>
                  <label className="block text-gray-700 mb-2">
                    Dokumen Terkait (Opsional)
                  </label>
                  <Upload
                    maxCount={1}
                    beforeUpload={() => false}
                    className="w-full"
                  >
                    <div className="flex items-center w-full">
                      <input
                        type="text"
                        placeholder="Unggah dokumen terkait"
                        className="flex-grow border border-gray-300 rounded-l-md p-2 bg-gray-100 w-[460px]"
                        readOnly
                      />
                      <Button
                        icon={<UploadOutlined />}
                        className="ml-2 px-6 py-5 border font-semibold bg-white border-primary-blue text-primary-blue hover:bg-primary-blue hover:text-white transition-colors duration-300"
                      >
                        Unggah
                      </Button>
                    </div>
                  </Upload>
                  <p className="text-xs text-gray-500 mt-1">
                    Ukuran file maksimum 5MB
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="mt-5">
            {productOwners.map((owner, index) => (
              <div key={owner.id} className="flex flex-row gap-5 mb-4">
                <div className="w-1/2">
                  <label className="block text-gray-700">
                    {index === 0
                      ? "Product Owner"
                      : `Product Owner ${index + 1}`}
                  </label>
                  <Input
                    suffix={<UserOutlined className="text-gray-400" />}
                    className="mt-1 h-12"
                    placeholder="Masukkan nama Product Owner"
                    value={owner.name}
                    onChange={(e) => {
                      const updatedOwners = [...productOwners];
                      updatedOwners[index].name = e.target.value;
                      setProductOwners(updatedOwners);
                    }}
                  />
                </div>

                <div className="w-1/2">
                  <label className="block text-gray-700">
                    {index === 0
                      ? "No. HP Product Owner"
                      : `No. HP Product Owner ${index + 1}`}
                  </label>
                  <div className="flex items-center">
                    <Input
                      className="mt-1 h-12"
                      placeholder="Masukkan No. HP Product Owner"
                      value={owner.phone}
                      onChange={(e) => {
                        const updatedOwners = [...productOwners];
                        updatedOwners[index].phone = e.target.value;
                        setProductOwners(updatedOwners);
                      }}
                    />
                    {productOwners.length > 1 && (
                      <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => removeProductOwner(owner.id)}
                        className="ml-2  px-4 py-5 font-semibold border bg-white border-red text-red hover:bg-primary-blue hover:text-white transition-colors duration-300"
                      >
                        Hapus
                      </Button>
                    )}
                    {index === productOwners.length - 1 &&
                      productOwners.length === 1 && (
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={addProductOwner}
                          className="ml-2 px-4 py-5 border font-semibold bg-white border-primary-blue text-primary-blue hover:bg-primary-blue hover:text-white transition-colors duration-300"
                        >
                          Tambahkan
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            ))}
            {productOwners.length > 1 && (
              <div className="flex justify-end">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={addProductOwner}
                  className="ml-2 px-4 py-5 border font-semibold bg-white border-primary-blue text-primary-blue hover:bg-primary-blue hover:text-white transition-colors duration-300"
                >
                  Tambahkan
                </Button>
              </div>
            )}
          </div>
          <div className="mt-5 border-b-2 pb-5">
            {developers.map((dev, index) => (
              <div key={dev.id} className="flex flex-row gap-5 mb-4">
                <div className="w-1/2">
                  <label className="block text-gray-700">
                    {index === 0
                      ? "PIC Developer"
                      : `PIC Developer ${index + 1}`}
                  </label>
                  <Input
                    suffix={<UserOutlined className="text-gray-400" />}
                    className="mt-1 h-12"
                    placeholder="Masukkan nama PIC Developer"
                    value={dev.name}
                    onChange={(e) => {
                      const updatedDevelopers = [...developers];
                      updatedDevelopers[index].name = e.target.value;
                      setDevelopers(updatedDevelopers);
                    }}
                  />
                </div>
                <div className="w-1/2">
                  <label className="block text-gray-700">
                    {index === 0
                      ? "No. HP PIC Developer"
                      : `No. HP PIC Developer ${index + 1}`}
                  </label>
                  <div className="flex items-center">
                    <Input
                      className="mt-1 h-12"
                      placeholder="Masukkan No. HP PIC Developer"
                      value={dev.phone}
                      onChange={(e) => {
                        const updatedDevelopers = [...developers];
                        updatedDevelopers[index].phone = e.target.value;
                        setDevelopers(updatedDevelopers);
                      }}
                    />
                    {developers.length > 1 && (
                      <Button
                        type="text"
                        danger
                        icon={<DeleteOutlined />}
                        onClick={() => removeDeveloper(dev.id)}
                        className="ml-2  px-4 py-5 font-semibold border bg-white border-red text-red hover:bg-primary-blue hover:text-white transition-colors duration-300"
                      >
                        Hapus
                      </Button>
                    )}
                    {index === developers.length - 1 &&
                      developers.length === 1 && (
                        <Button
                          type="primary"
                          icon={<PlusOutlined />}
                          onClick={addDeveloper}
                          className="ml-2 px-4 py-5 border font-semibold bg-white border-primary-blue text-primary-blue hover:bg-primary-blue hover:text-white transition-colors duration-300"
                        >
                          Tambahkan
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            ))}
            {developers.length > 1 && (
              <div className="flex justify-end">
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={addDeveloper}
                  className="ml-2 px-4 py-5 border font-semibold bg-white border-primary-blue text-primary-blue hover:bg-primary-blue hover:text-white transition-colors duration-300"
                >
                  Tambahkan
                </Button>
              </div>
            )}
          </div>
          <div className="w-full flex justify-end mt-6">
            <Button type="primary" onClick={handleNext}>
              Selanjutnya
            </Button>
          </div>
        </>
      ),
    },
    {
      title: "Cek Kuota",
      content: (
        <div className="overflow-x-auto">
          <Table
            className="[&_.ant-table-thead>tr>th]:!bg-primary-blue [&_.ant-table-thead>tr>th]:!text-white"
            rowSelection={{ type: "checkbox" }}
            columns={columns}
            dataSource={data}
            pagination={false}
          />
          <div className="w-full flex justify-end mt-6">
            <Button onClick={handlePrev}>Kembali</Button>
            <Button type="primary" onClick={handleNext} className="ml-2">
              Selanjutnya
            </Button>
          </div>
        </div>
      ),
    },
    {
      title: "Approval",
      content: (
        <div>
          {checkers.map((checker, index) => (
            <div key={index} className="mb-4">
              <label className="block text-gray-700">
                Checker / Signer {index + 1}
              </label>
              <div className="flex items-center gap-2">
                <Input
                  className="w-full"
                  placeholder={`Masukkan Checker / Signer ${index + 1}`}
                  value={checker.value}
                  onChange={(e) =>
                    handleCheckerChange(checker.id, e.target.value)
                  }
                  prefix={<SearchOutlined />}
                />
                {checkers.length > 1 && (
                  <Button
                    type="primary"
                    danger
                    onClick={() => handleRemoveChecker(checker.id)}
                  >
                    <DeleteOutlined /> Hapus
                  </Button>
                )}
              </div>
              <p className="text-red-500 text-sm mt-2">
                Job Grade minimal JG08 dan lebih tinggi dari Maker
              </p>
            </div>
          ))}
          <Button type="dashed" onClick={handleAddChecker}>
            <PlusOutlined /> Tambahkan
          </Button>
          {/* <div className="w-full flex justify-between mt-6">
            <Button onClick={handlePrev}>Kembali</Button>
            <Button type="primary" onClick={handleNext}>
              Kirim Pengajuan
            </Button>
          </div> */}
          <div className="w-full flex justify-between mt-6">
            <Button onClick={handlePrev}>Kembali</Button>
            <Button type="primary" onClick={showModal}>
              Kirim Pengajuan
            </Button>
          </div>
        </div>
        //   <div>
        //   {checkers.map((checker, index) => (
        //     <div key={index} className="mb-4">
        //       <label className="block text-gray-700">
        //         Checker / Signer {index + 1}
        //       </label>
        //       <div className="flex items-center gap-2">
        //         <Input
        //           className="w-full"
        //           placeholder={`Masukkan Checker / Signer ${index + 1}`}
        //           value={checker.value}
        //           onChange={e =>
        //             handleCheckerChange(checker.id, e.target.value)
        //           }
        //           prefix={<SearchOutlined />}
        //         />
        //         {index === 0 && (
        //           <Button type="dashed" onClick={handleAddChecker}>
        //             <PlusOutlined /> Tambahkan
        //           </Button>
        //         )}
        //         {index > 0 && (
        //           <Button
        //             type="primary"
        //             danger
        //             onClick={() => handleRemoveChecker(checker.id)}
        //           >
        //             <DeleteOutlined /> Hapus
        //           </Button>
        //         )}
        //       </div>
        //       <p className="text-red-500 text-sm mt-2">
        //         Job Grade minimal JG08 dan lebih tinggi dari Maker
        //       </p>
        //     </div>
        //   ))}
        //   {checkers.length > 1 && (
        //     <Button type="dashed" onClick={handleAddChecker}>
        //       <PlusOutlined /> Tambahkan
        //     </Button>
        //   )}
        //   <div className="w-full flex justify-between mt-6">
        //     <Button onClick={handlePrev}>Kembali</Button>
        //     <Button type="primary" onClick={handleNext}>
        //       Kirim Pengajuan
        //     </Button>
        //   </div>
        // </div>
      ),
    },
  ];

  return (
    <>
      <div className="p-4">
        <div className="w-full flex border-b-2">
          <div className="w-1/2 flex flex-row justify-between">
            <Outlet
              titlePage="Tambahkan Kuota E-CDD"
              description="Data realisasi diperbarui 20 Sep 2024 - 14:30"
            />
          </div>
          {/* Progress Bar */}
          <div className="w-1/2 flex flex-row justify-between p-6">
            <Steps current={currentStep}>
              {steps.map((step, index) => (
                <Step key={index} title={step.title} />
              ))}
            </Steps>
          </div>
        </div>
      </div>

      <div className="w-full p-4">
        {/* Step Content */}
        <div className="mt-2">{steps[currentStep].content}</div>
      </div>

      {/* Modal Konfirmasi */}
      <Modal
        title={null}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div className="flex flex-col items-center">
          <img
            src="/images/Modal1.png"
            alt="Konfirmasi"
            className="mb-4"
            style={{ width: "150px", height: "150px" }}
          />
          <h3 className="text-lg font-semibold mb-2">Konfirmasi Pengajuan</h3>
          <p className="text-sm text-center">
            Apakah Anda yakin seluruh data pengajuan E-CDD Anda sudah sesuai?
          </p>
          <div className="flex justify-center w-full mt-4">
            <Button
              key="back"
              onClick={handleCancel}
              className="w-1/2 mr-2"
              style={{ height: "40px", display: "block" }}
            >
              Cek Ulang
            </Button>
            <Button
              key="submit"
              type="primary"
              onClick={handleOk}
              className="w-1/2"
              style={{ height: "40px", display: "block" }}
            >
              Ya
            </Button>
          </div>
        </div>
      </Modal>

      {/* Modal Pengajuan Berhasil */}
      <Modal
        title={null}
        visible={isSubmittedModalVisible}
        onOk={handleOkSubmit}
        footer={null}
      >
        <div className="flex flex-col items-center">
          <img
            src="/images/Berhasil.png"
            alt="Berhasil"
            className="mb-4"
            style={{ width: "150px", height: "150px" }}
          />
          <h3 className="text-lg font-semibold mb-2">
            Pengajuan Berhasil Dikirim
          </h3>
          <p className="text-sm text-center">
            Anda bisa melihat status pengajuan melalui menu Monitoring Pengajuan
          </p>
          <div className="flex justify-center w-full mt-4">
            <Button
              key="monitor"
              type="primary"
              onClick={handleOkSubmit}
              className="w-1/2"
              style={{ height: "40px", display: "block" }}
            >
              Monitoring Pengajuan
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ECDDForm;
