import React from "react";
import { Button } from "antd";

const SearchBar = () => {
  return (
    <>
      <div className="flex justify-between items-center">
        <div className="flex items-center border p-2 rounded-md w-full mr-4">
          <input
            type="text"
            placeholder="Cari Produk / Layanan"
            className="flex-grow outline-none"
          />
          <img
            src="/images/Search.png"
            alt="Search Icon"
            className="w-4 h-4 mr-4"
          />
        </div>
        <Button className="bg-white text-primary-blue  border-primary-blue border-2 rounded-md  font-semibold w-full h-fit">
          <div className="flex gap-2 justify-center items-center text-lg">
            Filter
            <img
              src="/images/Filter.png"
              alt="Filter Icon"
              className="w-4 h-4 "
            />
          </div>
        </Button>
      </div>
    </>
  );
};

export default SearchBar;
