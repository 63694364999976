import React from "react";
import "antd/dist/reset.css";
import { Table } from "antd";
import Outlet from "../../../organisms/Outlet";

const ServiceInfo = () => {
  const serviceInfoData = [
    { key: "title", title: "Title", value: "Layanan 1" },
    { key: "version", title: "Version", value: "v2.0" },
    { key: "url", title: "URL", value: "www.layanan1.net" },
  ];

  const versionControlColumns = [
    {
      title: "Doc Version",
      dataIndex: "docVersion",
      key: "docVersion",
    },
    {
      title: "API Version",
      dataIndex: "apiVersion",
      key: "apiVersion",
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
    },
    {
      title: "Document Link",
      dataIndex: "documentLink",
      key: "documentLink",
      render: (text) => <a href="#">{text}</a>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const versionControlData = [
    {
      key: "1",
      docVersion: "v1.0",
      apiVersion: "v1.0",
      date: "7 December 2021",
      documentLink: "Here",
      description: "Baseline Version",
    },
    {
      key: "2",
      docVersion: "v2.0",
      apiVersion: "v2.0",
      date: "22 August 2022",
      documentLink: "This Pages",
      description: (
        <ul className="list-disc pl-4">
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li>
            Duis orci quam, eleifend mollis venenatis at, gravida ut lacus.
          </li>
          <li>Mauris tempus sed quam sed condimentum.</li>
        </ul>
      ),
    },
  ];

  const endpointColumns = [
    {
      title: "Endpoint",
      dataIndex: "endpoint",
      key: "endpoint",
    },
    {
      title: "Method",
      dataIndex: "method",
      key: "method",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  const endpointData = [
    {
      key: "1",
      endpoint: "Login",
      method: "POST",
      url: "/api/login",
      description: "User login endpoint",
    },
  ];

  return (
    <>
      <div className="p-4">
        <div className="w-full flex border-b-2">
          <div className="w-1/2 flex flex-row justify-between">
            <Outlet titlePage="Service" />
          </div>
        </div>
        <div className="w-full ">
          <div className="p-0">
            <div className="bg-white p-6">
              <h2 className="text-2xl font-bold mb-4">Services Information</h2>
              <table className="w-full border-collapse">
                <tbody>
                  {serviceInfoData.map((item) => (
                    <tr key={item.key}>
                      <td className="bg-primary-blue text-white p-4 font-semibold">
                        {item.title}
                      </td>
                      <td className="border p-4">{item.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="bg-white p-6">
              <h2 className="text-2xl font-bold mb-4">Version Control</h2>
              <Table
                columns={versionControlColumns}
                dataSource={versionControlData}
                pagination={false}
                className="[&_.ant-table-thead>tr>th]:!bg-primary-blue [&_.ant-table-thead>tr>th]:!text-white"
              />

              <h2 className="text-2xl font-bold mb-4 mt-8">
                Product Description
              </h2>
              <p>
                Provide details about the products here. You can use official
                descriptions or provide your own. Feel free to include any
                relevant information.
              </p>

              <h2 className="text-2xl font-bold mb-4 mt-8">Endpoints</h2>
              <Table
                columns={endpointColumns}
                dataSource={endpointData}
                pagination={false}
              />

              <h2 className="text-2xl font-bold mb-4 mt-8">Request Example</h2>
              <pre className="bg-gray-100 p-4 rounded">
                {`{"username": "user@example.com","password": "password123"}`}
              </pre>
              <h2 className="text-2xl font-bold mb-4 mt-8">Response Example</h2>
              <pre className="bg-gray-100 p-4 rounded">
                {`{"status": "success","data": {"token": "your_jwt_token"}}`}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServiceInfo;
