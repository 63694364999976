import React, { useState, useEffect } from "react";
import Outlet from "../../organisms/Outlet";
import { useBreadcrumb } from "../../../context/BreadCrumbContext";
import SearchBar from "../../molecules/SearchBar";
import DataTableKuota from "../../molecules/DataTableKuota";
import { Button } from "antd";
import { Link } from "react-router-dom";

const QuotaAddition = () => {
  const { setIsBreadcrumbVisible } = useBreadcrumb();
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsBreadcrumbVisible(false);
    return () => setIsBreadcrumbVisible(true);
  }, [setIsBreadcrumbVisible]);

  useEffect(() => {
    setData([
      {
        product: "BRImo",
        service: "FR Request",
        provider: "Privy",
        quota: "20 / 1000",
        lastUpdated: "Diperbarui 20 Sep 2024 - 14:30",
      },
      {
        product: "BRImo",
        service: "Active Liveness",
        provider: "Privy",
        quota: "1990 / 2000",
        lastUpdated: "Diperbarui 20 Sep 2024 - 14:30",
      },
      {
        product: "BRImo",
        service: "Active Liveness",
        provider: "Privy",
        quota: "3000 / 3000",
        lastUpdated: "Diperbarui 20 Sep 2024 - 14:30",
      },
    ]);
  }, []);

  return (
    <div className="p-6 w-full h-fit">
      <div className="flex justify-between items-start mb-4">
        <div className="w-1/2 flex flex-row">
          <Outlet titlePage="Penambahan Kuota E-CDD" description="" flex />
        </div>

        <div className="flex items-center">
          <SearchBar placeholder="Cari Produk / Layanan" />
          <Link to="/penambahan-kuota/tambah-kuota">
            <Button type="primary" className="ml-2 h-10">
              Tambah Kuota
              <img
                src="/images/PlusCircle.png"
                alt="Filter Icon"
                className="w-4 h-4"
              />
            </Button>
          </Link>
        </div>
      </div>
      <DataTableKuota data={data} />
    </div>
  );
};

export default QuotaAddition;
