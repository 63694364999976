import React from "react";
import "antd/dist/reset.css";
import { DownloadOutlined } from "@ant-design/icons";
import Outlet from "../../../organisms/Outlet";

const DetailPengajuan = () => {
  return (
    <>
      <div className="p-4">
        <div className="w-full flex border-b-2 mb-4">
          <div className="w-1/2 flex flex-row justify-between">
            <Outlet
              titlePage="Detail E-CDD"
              // description="Data realisasi diperbarui 20 Sep 2024 - 14:30"
            />
          </div>
        </div>

        <div className="w-full p-4 "> 
          <div className="grid grid-cols-2 gap-4">
            <div>
              <p>
                <strong>Produk:</strong> BRImo
              </p>
              <p>
                <strong>Jenis Pengajuan:</strong> Penambahan Kuota
              </p>
              <p>
                <strong>Kebutuhan Kuota:</strong> 3000
              </p>
              <p>
                <strong>Product Owner:</strong> 00321186 / Neneng Nurlaila Utami
              </p>
              <p>
                <strong>PIC Developer:</strong> 00321211 / Bambang Renaldi
              </p>
              <p>
                <strong>Diajukan Oleh:</strong> 00321186 / Neneng Nurlaila Utami
              </p>
              <p>
                <strong>Tanggal Pengajuan:</strong> 10 September 2021
              </p>
            </div>
            <div>
              <p>
                <strong>Nama Layanan:</strong> FR Request
              </p>
              <p>
                <strong>Provider:</strong> Privy
              </p>
              <p>
                <strong>Alasan Pengajuan:</strong> Penambahan kuota BRImo
              </p>
              <p>
                <strong>No. HP Product Owner:</strong> 081356478455
              </p>
              <p>
                <strong>No. HP PIC Developer:</strong> 0812657394858
              </p>
              <p>
                <strong>Approval Atasan:</strong> 00321132 / Bambang Pamungkas
              </p>
              <p>
                <strong>Dokumen Terkait:</strong>{" "}
                <a
                  href="/path/to/BRImo_FST.pdf"
                  className="text-blue-500 flex items-center"
                >
                  <DownloadOutlined className="mr-1" />
                  BRImo_FST.pdf
                </a>
              </p>
            </div>
          </div>

          <div className="mt-4 p-4 bg-gray-100 rounded">
            <p>
              <strong>Catatan Pengajuan</strong>
            </p>
            <p>Bambang Pamungkas (Checker/Signer) - 10 Sep 2021 - 14:32</p>
            <p>"Kebutuhan belum sesuai"</p>
          </div>

          <div className="flex justify-end mt-4">
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600">
              Ajukan Ulang
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailPengajuan;
