import React, { useState, useEffect } from "react";
import Outlet from "../../organisms/Outlet";
import { useBreadcrumb } from "../../../context/BreadCrumbContext";
// import SummaryCard from "../../molecules/SummaryCards";
import SearchBar from "../../molecules/SearchBar";
import DataTable from "../../molecules/DataTable";

const MonitoringUsage = () => {
  const { setIsBreadcrumbVisible } = useBreadcrumb();
  const [data, setData] = useState([]);

  useEffect(() => {
    setIsBreadcrumbVisible(false);
    return () => setIsBreadcrumbVisible(true);
  }, [setIsBreadcrumbVisible]);

  useEffect(() => {
    // Fetch data from API or set static data
    setData([
      {
        product: "BRImo",
        service: "FR Request",
        provider: "Privy",
        quota: "20 / 1000",
        lastUpdate: "20 Sep 2024 - 14:30",
      },
      {
        product: "BRImo",
        service: "Active Liveness",
        provider: "Privy",
        quota: "1990 / 2000",
        lastUpdate: "20 Sep 2024 - 14:30",
      },
      {
        product: "Briguna",
        service: "FR Request",
        provider: "Privy",
        quota: "20 / 1000",
        lastUpdate: "20 Sep 2024 - 14:30",
      },
      {
        product: "Ceria",
        service: "Active Liveness",
        provider: "Privy",
        quota: "Data Tidak Tersedia",
        lastUpdate: "20 Sep 2024 - 14:30",
      },
      {
        product: "Senyum",
        service: "FR Request",
        provider: "Asliri",
        quota: "20 / 1000",
        lastUpdate: "20 Sep 2024 - 14:30",
      },
      {
        product: "Senyum",
        service: "Active Liveness",
        provider: "Asliri",
        quota: "3000 / 3000",
        lastUpdate: "20 Sep 2024 - 14:30",
      },
    ]);
  }, []);

  return (
    <>
      <div className="border-b-2">
        <div className="w-full flex flex-row justify-between p-6">
          <Outlet titlePage="Monitoring Usage" description=""></Outlet>
          <SearchBar />
        </div>
        {/* <div className="p-2">
          <div className="flex justify-between mb-4 gap-x-7">
            <SummaryCard
              title="Total Produk"
              value="8"
              image="/images/Vector.png"
              imageWidth="40px"
              imageHeight="40px"
            />
            <SummaryCard
              title="Total Realisasi Kuota"
              value="7060 / 11000"
              image="/images/Investasi.png"
              imageWidth="60px"
              imageHeight="60px"
            />
          </div>
        </div> */}
      </div>
      <div className="w-full p-6">
        <DataTable data={data} />
      </div>
    </>
  );
};

export default MonitoringUsage;
