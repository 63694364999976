import React, { useEffect } from "react";
import { Table, Button, Tag } from "antd";
import { useBreadcrumb } from "../../../context/BreadCrumbContext";
import Outlet from "../../organisms/Outlet";
import SearchBar from "../../molecules/SearchBar";
import { Link } from "react-router-dom";

const data = [
  {
    key: "1",
    layanan: "Layanan 1",
    jenisPengajuan: "Penambahan Kuota",
    tanggalPengajuan: "10 September 2021",
    statusPengajuan: "Berhasil",
    statusColor: "green",
  },
  {
    key: "2",
    layanan: "Layanan 2",
    jenisPengajuan: "Penambahan Kuota",
    tanggalPengajuan: "10 September 2021",
    statusPengajuan: "Menunggu Approval Checker",
    statusColor: "orange",
  },
  {
    key: "3",
    layanan: "Layanan 3",
    jenisPengajuan: "Pergantian Provider",
    tanggalPengajuan: "10 September 2021",
    statusPengajuan: "Menunggu Approval Signer",
    statusColor: "yellow",
  },
  {
    key: "4",
    layanan: "Layanan 4",
    jenisPengajuan: "Pergantian Provider",
    tanggalPengajuan: "21 Juni 2022",
    statusPengajuan: "Diterima Admin",
    statusColor: "yellow",
  },
  {
    key: "5",
    layanan: "Layanan 5",
    jenisPengajuan: "Penambahan Kuota",
    tanggalPengajuan: "04 Agustus 2023",
    statusPengajuan: "Diproses Admin",
    statusColor: "yellow",
  },
  {
    key: "6",
    layanan: "Layanan 6",
    jenisPengajuan: "Penambahan Kuota",
    tanggalPengajuan: "01 Agustus 2023",
    statusPengajuan: "Ditolak Admin",
    statusColor: "red",
  },
];

const columns = [
  {
    title: "Layanan",
    dataIndex: "layanan",
    key: "layanan",
  },
  {
    title: "Jenis Pengajuan",
    dataIndex: "jenisPengajuan",
    key: "jenisPengajuan",
  },
  {
    title: "Tanggal Pengajuan",
    dataIndex: "tanggalPengajuan",
    key: "tanggalPengajuan",
  },
  {
    title: "Status Pengajuan",
    dataIndex: "statusPengajuan",
    key: "statusPengajuan",
    render: (text, record) => <Tag color={record.statusColor}>{text}</Tag>,
  },
  {
    title: "Detail",
    key: "detail",
    render: () => (
      <Button type="primary">
        <Link
          to="/monitoring-pengajuan/detail-pangajuan"
          className="flex items-center"
        >
          Lihat Deatil
        </Link>
      </Button>
    ),
  },
];

const MonitoringSubmission = () => {
  const { setIsBreadcrumbVisible } = useBreadcrumb();

  useEffect(() => {
    setIsBreadcrumbVisible(false);
    return () => setIsBreadcrumbVisible(true);
  }, [setIsBreadcrumbVisible]);

  return (
    <div className="p-4">
      <div className="flex justify-between items-center my-4">
        <Outlet titlePage="Monitoring Pengajuan" description="" />
        <div className="flex items-center">
          <SearchBar />
        </div>
      </div>
      <Table
        className="[&_.ant-table-thead>tr>th]:!bg-primary-blue [&_.ant-table-thead>tr>th]:!text-white"
        columns={columns}
        dataSource={data}
        pagination={{ pageSize: 10 }}
      />
    </div>
  );
};

export default MonitoringSubmission;
