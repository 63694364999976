import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
console.log(BASE_URL);

const axiosInstance = axios.create({
  baseURL: BASE_URL + "/api/v1",
  headers: {
    "Content-Type": "application/json",
    Authorization: "token", // Set token disini
  },
});

// Function to handle login
async function login() {
  try {
    // Replace these with actual user credentials or a method to obtain them
    const credentials = {
      pn: process.env.REACT_APP_LOGIN_PN,
      password: process.env.REACT_APP_LOGIN_PW,
    };

    // Make a request to the login API
    const response = await axios.post(
      BASE_URL + "/api/v1/user/login",
      credentials
    );

    // Assuming the token is in the response, save it to local storage
    const token = response.data.token;
    localStorage.setItem("token", token);

    // Optionally, reload the page or navigate to a specific route
    window.location.reload();
  } catch (error) {
    console.error("Login failed:", error);
    // Handle login errors, e.g., show a message to the user
  }
}

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    // Retrieve the token from local storage
    let token = localStorage.getItem("token");

    // If the token doesn't exist, call the login function
    if (!token) {
      await login();
      token = localStorage.getItem("token");
    }

    // Append the token to the Authorization header
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    // Handle request errors
    return Promise.reject(error);
  }
);

export default axiosInstance;

const registerECDDService = async (data, attachment) => {
  const formData = new FormData();
  data = {
    product: {
      name: "BRImo",
      division: "FST",
      purpose: "Pengadaan Kuota BRImo",
    },
    product_owner: [
      {
        employee_id: "dc1d3c19-39fb-4573-bede-8d1dbebfc557",
        phone: "08100001",
      },
      {
        employee_id: "41c81221-1076-46ea-9bbc-8b68b0b0b404",
        phone: "00321002",
      },
    ],
    product_developer: [
      {
        employee_id: "2ddc67c2-31ad-454a-b8f4-dc450448f53b",
        phone: "00321003",
      },
      {
        employee_id: "17e4fdf3-6099-4f71-84ee-a2fff7ef3940",
        phone: "00321004",
      },
    ],
    product_service: [
      {
        service_id: "1caa8ad0-bfd2-4f2d-b002-24554160ae23",
        quota: 1000,
        request_type: "Pendaftaran",
      },
      {
        service_id: "75a4902e-1089-431d-90ad-da9b14bccb55",
        quota: 2000,
        request_type: "Pendaftaran",
      },
    ],
    product_signer: {
      employee_id: "32bc57ef-25b2-44e1-9c40-cdfb41b71dd7",
      phone: "00321005",
    },
  };

  formData.append("data", JSON.stringify(data));
  if (attachment) {
    console.log(attachment);
    formData.append("attachment", attachment);
  }

  try {
    const response = await axiosInstance.post(
      "/service/registration",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.error(error); // Log error details
    throw error;
  }
};

const getECDDServices = async () => {
  try {
    const response = await axiosInstance.get("/service/list");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const eCDDService = {
  registerECDDService,
  getECDDServices,
};
