// contexts/BreadcrumbContext.js
import React, { createContext, useState, useContext } from "react";

const BreadcrumbContext = createContext();

export const BreadcrumbProvider = ({ children }) => {
  const [isBreadcrumbVisible, setIsBreadcrumbVisible] = useState(true);

  return (
    <BreadcrumbContext.Provider
      value={{ isBreadcrumbVisible, setIsBreadcrumbVisible }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumb = () => useContext(BreadcrumbContext);
